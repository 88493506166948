"use client";
import React from "react";

interface Props {
	title?: string;
	text?: string;
	lineColor?: string;
	areH1?: boolean;
	isDark?: boolean;
}

export default function WidgetTitle({
	title,
	text,
	lineColor,
	areH1,
	isDark,
}: Props) {
	if (!title) {
		return null;
	}

	const heading = areH1 ? "h1" : "h2";

	const lineClass = lineColor === "#FF3640" ? "primary-50" : "link";
	const titleColor = isDark ? "inverted" : "primary";
	const descColor = isDark ? "inverted" : "neutral-50";

	return (
		<header>
			<div >
				<span className={`block w-full h-1 bg-${lineClass}`}/>
				<div className={`mt-4 pr-2 pl-2 md:pr-4 md:pl-4 xl:pr-4 xl:pl-4`}>
					{title &&
						React.createElement(
							heading,
							{ className: `text-2xl xl:text-3xl font-black text-${titleColor}` },
							title
					)}
					{text && <p className={`text-md xl:text-base text-${descColor} mt-0`}>{text}</p>}
				</div>
			</div>
		</header>
	);
}
