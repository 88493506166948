import Link from "next/link";
import Image from "next/image";

import { NewsInfo } from "../../organisms/BloqueHeroGrid/interfaces/HeroGridResponse";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import Icon from "../../atoms/Icon/Icon";
import SingleCardImage from "./SingleCardImage";
import InteractiveLink from "../../organisms/BloqueHeroGrid/InteractiveLink";
import { BlockDataType } from "@/app/types/BlockDataType";

const SingleCard = ({
	news,
	noContent,
	theme,
	visibleClass,
	loading,
	blockData,
	cardPosition,
}: {
	news: NewsInfo;
	noContent: string;
	theme: string;
	visibleClass?: string | undefined;
	loading: "eager" | "lazy";
	blockData: BlockDataType;
	cardPosition: number;
}) => {
	const typeOfNews = "video"; // news?.type?.split("--")[1];
	if (!typeOfNews) return <></>;
	return (
		<>
			<figure>
				<InteractiveLink
					url={news?.newsUrl || ""}
					title={
						news?.altImage?.toUpperCase() ||
						news?.editorialTitle?.toUpperCase() ||
						"Noticia N+"
					}
					blockData={blockData}
					cardPosition={cardPosition}
				>
					{typeOfNews === "video" ? (
						<div className="relative">
							<SingleCardImage
								loading={loading}
								noContent={noContent}
								news={news}
							/>
							{news?.videoDuration ? (
								<span
									className="absolute right-2 bottom-2 bg-secondary-90 text-secondary-inverted pt-0.5 pb-0.5 pr-5 pl-2 rounded-md text-sm after:absolute play-icon after:w-5 after:h-5 after:right-0.5 after:top-0"
								>
									{secondsToMinutes(news?.videoDuration)}
								</span>
							) : news?.isLiveblog ? (
								<>
									<div
										className=""
									>
										<span
											className=""
										>
											LIVEBLOG
										</span>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					) : (
						<div>
							<SingleCardImage
								loading={loading}
								noContent={noContent}
								news={news}
							/>
						</div>
					)}
				</InteractiveLink>
				<figcaption>
					<span
						className="text-xs uppercase font-bold tracking-wide text-secondary-focus"
						title={news?.tagBlock?.toUpperCase() || ""}
					>
						{news?.tagBlock?.toUpperCase() || ""}
					</span>
					<InteractiveLink
						url={news?.newsUrl || ""}
						title={news?.editorialTitle || news?.title || "Noticia N+"}
						aria-label={news?.editorialTitle || news?.title || "Noticia N+"}
						blockData={blockData}
						cardPosition={cardPosition}
						isLink={false}
					>
						<p
							className="text-md font-bold md:text-base text-secondary-default"
							title={news?.editorialTitle || news?.title || ""}
						>
							{news?.editorialTitle || news?.title || ""}
						</p>
					</InteractiveLink>
				</figcaption>
			</figure>
		</>
	);
};

export default SingleCard;
