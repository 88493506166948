"use client";

import Link from "next/link";
import Image from "next/image";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { usePathname } from "next/navigation";
import { ImgLinkProps } from "@/app/types/ImgLinkProps.type";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";

declare const window: WindowWithDataLayer;
export default function ImageLink({
	post,
	blockData,
	cardPosition,
	loading,
}: ImgLinkProps) {
	const pathName = usePathname();
	let imgSizes = "60vw";
	if (blockData?.tipoBloque === "BloqueAperturaLive") {
		imgSizes ="(min-width: 1440px) 14vw, (min-width: 1280px) 13vw, (min-width: 1024px) 24vw, (min-width: 820px) 25vw, (min-width:768px) 23vw, (min-width:540px) 38vw, 50vw";
	}
	const trailingPath = `${post?.path}/`?.replace(/\/+$/, "/");

	let stylesImg = {};

	if (blockData.tipoBloque === "BloqueVideosDemanda") {
		stylesImg = {maxWidth: "320px"};
	}
	return (
		<>
			<Image
				src={post?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"}
				alt={post?.thumbnail?.alt ?? "Imagen default"}
				width={post?.thumbnail?.width ?? 320}
				height={post?.thumbnail?.height ?? 180}
				sizes={imgSizes}
				loading={loading}
				onError={() => "/assets/placeholder_.svg"}
				className="w-full"
			/>
			<Link
				href={trailingPath ?? {}}
				title={post?.title}
				prefetch={false}
				onClick={() => handleDataLayerOnClick(blockData, cardPosition, pathName)}
			/>
		</>
	);
}
