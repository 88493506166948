import VideoDuration from "../../atoms/VideoDuration/VideoDuration";
import { PostCard } from "@/app/types/PostCard.type";
import ImageLink from "../../atoms/ImageLink/ImageLink";
import { BlockDataType } from "@/app/types/BlockDataType";
import InteractiveLink from '../../organisms/BloqueHeroGrid/InteractiveLink';

type Props = {
  post: PostCard;
  isDark: boolean;
  blockData: BlockDataType
  cardPosition: number
}
export default function CardVideoNews( {post, isDark, ...rest}:Props){
  const isVideo = post?.isVideo;
  const loading = rest?.blockData?.posicionBloque === 0 || rest?.blockData?.posicionBloque === 1 ? "eager" : "lazy";
  let imgSizes = "(max-width: 414px) 62.8vw, (max-width: 540px) 50vw, (max-width: 720px) 36.11vw, (max-width: 768px) 33.85vw, (max-width: 820px) 31.7vw, (max-width: 1024px) 25.39vw, (max-width: 1280px) 20.31vw, (max-width: 1440px) 18.05vw";
  if(rest?.blockData?.tipoBloque === "bloque_apertura_live"){
    imgSizes = "(max-width: 414px) 92.27vw, (max-width: 540px) 94.07vw, (max-width: 720px) 46.66vw, (max-width: 768px) 46.87vw, (max-width: 820px) 47.03vw, (max-width: 1024px) 47.65vw, (max-width: 1280px) 23.43vw, (max-width: 1440px) 23.61vw";
  }
  const hasTerms = (post?.term?.length > 0);
  const blockData = {...rest?.blockData, content_name: isVideo ? "VOD>" : "NEW>"};
  return (
    <figure id={post?.id} className="w-[17.5rem] grow-0 shrink-0 basis-[17.5rem] ml-4 xl:ml-0">
      <div className="relative">
      <ImageLink 
        post={post} 
        loading={loading} 
        blockData={blockData}
        cardPosition={rest?.cardPosition}
      />
        { isVideo && 
          <InteractiveLink title={post?.title} url={post?.path ?? "/"} cardPosition={rest?.cardPosition} blockData={blockData}>
            <VideoDuration duration={post?.duration} isPlay={false} /> 
          </InteractiveLink>
        }
      </div>
      <figcaption className="p-2">
        <span className="text-xs  uppercase font-bold tracking-wide text-secondary-focus">
          {hasTerms ? post?.term[0]?.name : post?.channel !== "Noticieros" ? post?.channel : "N+" }
        </span>
        <h3 className="text-md font-bold md:text-base text-secondary-default">
          <InteractiveLink title={post?.title} url={post?.path ?? "/"} cardPosition={rest?.cardPosition} blockData={blockData}>
            {post?.title}
          </InteractiveLink>
        </h3>
      </figcaption>
    </figure>
  );
}