"use client";

import { Children, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
	EffectCoverflow,
	Navigation,
	Pagination,
	Zoom,
	FreeMode,
	Thumbs,
} from "swiper/modules";
import Image from "next/image";
import parse from "html-react-parser";

import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "@/app/components/molecules/SlideShow/DesktopStyles.css";
import "@/app/styles/Tailwind/SlideShow.css";

import Icon from "../../atoms/Icon/Icon";
import { ImagesNews } from "../../organisms/BlockSlideShow/interfaces/SlideShow.interface";
import { getNameImageType } from "@/app/helpers/image/getTypeNameImage";
import { LoadingSlideShow } from "./LoadingSlideShow";
import { EventData } from "@/app/types/EventData.type";
import ButtonOpen from "../../atoms/ButtonOpen/ButtonOpen";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { usePathname } from "next/navigation";

import TailwindSetting from "./tailwind.json";

const SlideShow = ({
	imagesNews,
	aspectRatio,
	theme,
	blockTitle,
	dataLayer,
	loading,
}: {
	imagesNews: ImagesNews[];
	aspectRatio: string;
	theme: string;
	blockTitle: string;
	dataLayer: EventData;
	loading: "eager" | "lazy";
}) => {
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [widthOfScreen, setWidthOfScreen] = useState<any>();
	const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
	const [isLoading, setisLoading] = useState(true);
	const typeOfRatio: keyof ImagesNews = getNameImageType(
		"image",
		aspectRatio
	) as keyof ImagesNews;

	const [initialImage, setInitialImage] = useState(0);
	const path = usePathname();
	const maxTitleCharacters = 96;
	const handleOpenZoom = (
		event: React.MouseEvent<HTMLButtonElement>,
		index: number
	) => {
		document.body.classList.add("slideShow--blockScroll");
		setInitialImage(index);
		setIsPreviewOpen(true);
	};
	const handleClosePreview = () => {
		document.body.classList.remove("slideShow--blockScroll");
		setIsPreviewOpen(false);
	};

	const handleChange = (ev: any, dataLayer: any) => {
		// TODO content_name revisar
		handleDataLayerOnClick(
			{ ...dataLayer, eventAction: "slide" },
			ev.activeIndex + 1,
			path,
			false
		);
	};
	const totalPreviewImages = imagesNews?.length <= 3 ? imagesNews?.length : 4;

	useEffect(() => {
		if (window) {
			setWidthOfScreen(window.innerWidth);
			setisLoading(false);
		}
	}, [widthOfScreen]);

	const { showPreview, showScreen } = (TailwindSetting.aspect as Record<string, any>)[aspectRatio] || {};
	const showTheme = (TailwindSetting.theme as Record<string, any>)[theme] || TailwindSetting.theme.dark;


	return (
		<>
			{isLoading && (
				<>
					<LoadingSlideShow aspectRatio={aspectRatio} />
				</>
			)}
			{/* Mobile Screen */}
			{widthOfScreen <= 834 && (
				<>
					<Swiper
						navigation={{
							nextEl: "swiper-button-next",
							prevEl: "swiper-button-prev",
						}}
						pagination={{
							clickable: true,
							el: ".slideShow__paginationButtons",
						}}
						scrollbar={{ draggable: true }}
						modules={[EffectCoverflow, Pagination, Navigation]}
						className="padding-top-custom"
						onSlideChange={(ev) => handleChange(ev, dataLayer)}
						style={
							{
								"--padding-top": showScreen.tablet.paddingTop || "",
							} as React.CSSProperties
						}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure>
													<div
														className={`relative flex justify-center h-full`}
													>
														<Image
															loading={loading}
															src={news[typeOfRatio]}
															width={500}
															height={500}
															quality={75}
															sizes="(max-width:834px) 100vw, (max-width:1280px)50vw, 30vw"
															alt={news.altImage}
															className="image-custom"
															style={
																{
																	"--heighImage": showScreen.desktop.heightImage || "100%",
																	"--widthImage": showScreen.desktop.widthImage || "100%",
																	"--aspect-ratio": showPreview.desktop.aspect || "",
																} as React.CSSProperties
															}
														/>
														<ButtonOpen
															blockData={{
																...dataLayer,
																contentTitle: "open zoom",
															}}
															cardPosition={index + 1}
															action={(event: any) =>
																handleOpenZoom(event, index)
															}
															className="slideShow__zoomButton"
															title={"open zoom"}
														>
															<Icon name="openZoom" />
														</ButtonOpen>
													</div>
													<figcaption
														className="slideShow__figcaption"
														style={
															{
																"--text-color": showTheme.color,
															} as React.CSSProperties
														}
													>
														{news.editorialTitle.slice(0, maxTitleCharacters)}
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div
							className="slideShow__paginationButtons"
							style={
								{
									"--swiper-pagination-bullet-inactive-color": `${
										theme === "light" ? "#00283B" : "rgba(255, 255, 255, 0.90)"
									}`,
									"--swiper-pagination-color": `${
										theme === "light" ? "#00283B" : "#fff"
									}`,
								} as React.CSSProperties
							}
						/>
					</Swiper>
				</>
			)}
			{/* Preview */}
			{widthOfScreen <= 834 && isPreviewOpen && (
				<div className="slideShow__preview">
					<Icon
						name="closePreview"
						className="slideShow__closePreviewButton"
						onClick={handleClosePreview}
					/>

					<Swiper
						zoom={true}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						}}
						pagination={{
							clickable: true,
							el: ".slideShow__paginationButtonsPreview",
						}}
						modules={[Pagination, Navigation, Zoom]}
						className="top-custom"
						style={
							{
								"--swiper-theme-color": "#fff",
								"--custom-top": showPreview.mobile.top || "",
							} as React.CSSProperties
						}
						initialSlide={initialImage}
					>
						{Children.toArray(
							imagesNews.map((news) => {
								return (
									<>
										<SwiperSlide key={news?.idImage}>
											{
												<figure
													className="pb-20"
												>
													<div
														className={`relative flex justify-center h-full swiper-zoom-container`}
													>
														<Image
															src={news[typeOfRatio]}
															width={500}
															height={500}
															sizes="(max-width:834px) 100vw"
															quality={75}
															alt={news?.altImage}
															className="image-custom object-cover overflow-hidden relative"
															style={
																{
																	"--heightImage": showPreview.mobile.heightImage || "100%",
																	"--widthImage": showPreview.mobile.widthImage || "100%",
																	"--aspect-ratio": showPreview.desktop.aspect || "",
																} as React.CSSProperties
															}
														/>
													</div>
													<figcaption
														className={`py-2 px-4 text-neutral-inverted bg-[#141515]`}
													>
														<h2
															className="text-base font-bold leading-[22px] mb-2"
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the parse set a <p></p> Tag */}
														<div
															className="slideShow__descriptionPreview"
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div
							className="flex justify-around items-center fixed h-[30px] w-full bottom-4 z-[1]"
						>
							<div
								className={`swiper-button-prev previewActionButton`}
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
							<div
								className="slideShow__paginationButtonsPreview"
								style={
									{
										"--swiper-pagination-bullet-inactive-color":
											"rgba(255, 255, 255, 0.40)",
										"--swiper-pagination-color": "#fff",
										"--swiper-pagination-bullet-inactive-opacity": "1",
									} as React.CSSProperties
								}
							/>
							<div
								className={`swiper-button-next previewActionButton`}
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
				</div>
			)}

			{/* Tablet Screen */}
			{widthOfScreen > 834 && widthOfScreen <= 1279 && (
				<section className="pt-0 px-4 pb-4 xl:pb-8">
					<Swiper
						spaceBetween={10}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs]}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
							disabledClass: "slideShow__swiper-button-disabled",
						}}
						className="padding-top-custom"
						onSlideChange={(ev) => handleChange(ev, dataLayer)}
						style={
							{
								"--padding-top": showScreen.desktop.paddingTop || ""
							} as React.CSSProperties
						}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure
													className="grid mb-3 grid-temp-columns-custom"
													style={
														{
															"--grid-template-columns": showScreen.desktop.gridTemplate || "",
														} as React.CSSProperties
													}
												>
													<div>
														<div
															className={`relative flex justify-center h-full`}
														>
															<Image
																loading={loading}
																src={news[typeOfRatio]}
																width={500}
																height={500}
																quality={75}
																sizes="(max-width:834px) 100vw"
																alt={news.altImage}
																className="image-custom"
																style={
																	{
																		"--heighImage": showScreen.desktop.heightImage || "100%",
																		"--widthImage": showScreen.desktop.widthImage || "100%",
																		"--aspect-ratio": showPreview.desktop.aspect || "",
																	} as React.CSSProperties
																}
															/>

															<ButtonOpen
																blockData={dataLayer}
																cardPosition={index + 1}
																action={(event: any) =>
																	handleOpenZoom(event, index)
																}
																className="slideShow__zoomButton"
																title={"open zoom"}
															>
																<Icon name="openZoom" />
															</ButtonOpen>
														</div>
													</div>

													<figcaption
														className="slideShow__figcaption"
													>
														<h2
															className="slideShow__editorialTitle"
															style={
																{
																	"--text-color": showTheme.color,
																	"--size-text": showScreen.tablet.text || "",
																	"--line-height-tablet": showScreen.tablet.lineHeight || "",
																} as React.CSSProperties
															}
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the parse set a <p></p> Tag */}
														<div
															className="slideShow__description"
															style={
																{
																	"--text-color": showTheme.color,
																	"--size-text": showScreen.tablet.summary || "",
																	"--line-height-tablet": showScreen.tablet.lineSummary || "",
																	"--anchor-color": showTheme.link,
																} as React.CSSProperties
															}
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div>
							<div
								className="swiper-button-prev swiperActionButton"
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>

							<div
								className="swiper-button-next swiperActionButton"
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
				</section>
			)}
			{/* Preview Tablet */}
			{widthOfScreen > 834 && widthOfScreen <= 1279 && isPreviewOpen && (
				<div className="slideShow__preview">
					<Icon
						name="closePreview"
						className="slideShow__closePreviewButton"
						onClick={handleClosePreview}
					/>

					<Swiper
						zoom={true}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						}}
						pagination={{
							clickable: true,
							el: ".slideShow__paginationButtonsPreview",
						}}
						modules={[Pagination, Navigation, Zoom]}
						className="top-custom"
						style={
							{
								"--swiper-theme-color": "#fff",
								"--custom-top": showPreview.tablet.top || "",
							} as React.CSSProperties
						}
						initialSlide={initialImage}
					>
						{Children.toArray(
							imagesNews.map((news) => {
								return (
									<>
										<SwiperSlide key={news?.idImage}>
											{
												<figure
													className="pb-20"
												>
													<div
														className="relative flex justify-center h-full swiper-zoom-container"
													>
														<Image
															src={news[typeOfRatio]}
															width={500}
															height={500}
															sizes="(max-width:834px) 100vw"
															quality={75}
															alt={news?.altImage}
															className="image-custom object-cover overflow-hidden relative"
															style={
																{
																	"--heightImage": showPreview.tablet.heightImage || "",
																	"--widthImage": showPreview.tablet.widthImage || "",
																	"--aspect-ratio": showPreview.desktop.aspect || "",
																} as React.CSSProperties
															}
														/>
													</div>
													<figcaption
														className="p-4 text-neutral-inverted bg-[#141515]"
													>
														<h2
															className="text-base font-bold leading-[22px] mb-2"
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														<div
															className="slideShow__descriptionPreview"
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div
							className="flex justify-around items-center fixed h-[30px] w-full bottom-4 z-[1]"
						>
							<div
								className="swiper-button-prev previewActionButton"
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
							<div
								className="slideShow__paginationButtonsPreview"
								style={
									{
										"--swiper-pagination-bullet-inactive-color":
											"rgba(255, 255, 255, 0.40)",
										"--swiper-pagination-color": "#fff",
										"--swiper-pagination-bullet-inactive-opacity": "1",
									} as React.CSSProperties
								}
							/>
							<div
								className="swiper-button-next previewActionButton"
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
				</div>
			)}

			{/* Desktop Screen */}
			{widthOfScreen >= 1280 && (
				<section className="pt-0 px-4 pb-4 xl:pb-8">
					<Swiper
						spaceBetween={10}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs]}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
							disabledClass: "slideShow__swiper-button-disabled",
						}}
						className="padding-top-custom"
						onSlideChange={(ev) => handleChange(ev, dataLayer)}
						style={
							{
								"--padding-top": showScreen.desktop.paddingTop || "",
							} as React.CSSProperties
						}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure
													className="grid mb-3 grid-temp-columns-custom"
													style={
														{
															"--grid-template-columns": showScreen.desktop.gridTemplate || "",
														} as React.CSSProperties
													}
												>
													<div>
														<div
															className={`relative flex justify-center h-full`}
														>
															<Image
																loading={loading}
																src={news[typeOfRatio]}
																width={500}
																height={500}
																quality={75}
																sizes="(max-width:834px) 100vw"
																alt={news.altImage}
																className="image-custom"
																style={
																	{
																		"--heighImage": showScreen.desktop.heightImage || "100%",
																		"--widthImage": showScreen.desktop.widthImage || "100%",
																		"--aspect-ratio": showPreview.desktop.aspect || "",
																	} as React.CSSProperties
																}
															/>

															<ButtonOpen
																blockData={dataLayer}
																cardPosition={index + 1}
																action={(event: any) =>
																	handleOpenZoom(event, index)
																}
																className="slideShow__zoomButton"
																title={"open zoom"}
															>
																<Icon name="openZoom"/>
															</ButtonOpen>
														</div>
													</div>

													<figcaption
														className="slideShow__figcaptionDesktop"
														style={
															{
																"--padding-container-description": showScreen.desktop.padding || "",
															} as React.CSSProperties
														}
													>
														<h2
															className="slideShow__editorialTitleDesktop"
															style={
																{
																	"--size-desktop": showScreen.desktop.text || "",
																	"--line-height-desktop": showScreen.desktop.lineHeight || "",
																	"--text-color": showTheme.color
																} as React.CSSProperties
															}
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the parse set a <p></p> Tag */}
														<div
															className="slideShow__descriptionDesktop"
															style={
																{
																	"--size-desktop": showTheme.summary || "",
																	"--line-height-desktop": showTheme.lineSummary || "",
																	"--text-color": showTheme.color,
																	"--anchor-color": showTheme.link
																} as React.CSSProperties
															}
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div>
							<div
								className="swiper-button-prev swiperActionButton"
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>

							<div
								className="swiper-button-next swiperActionButton"
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
				</section>
			)}
			{/* Preview Desktop */}
			{widthOfScreen >= 1280 && isPreviewOpen && (
				<>
					<div className="slideShow__preview">
						<Icon
							name="closePreview"
							className="slideShow__closePreviewButton"
							onClick={handleClosePreview}
						/>

						<Swiper
							zoom={true}
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							pagination={{
								clickable: true,
								el: ".slideShow__paginationButtonsPreview",
							}}
							modules={[Pagination, Navigation, Zoom]}
							className={`max-w-[1440px] pb-[50px] top-custom`}
							style={
								{
									"--swiper-theme-color": "#fff",
									"--custom-top": `${showPreview.desktop.top || 0}`,
								} as React.CSSProperties
							}
							initialSlide={initialImage}
						>
							{Children.toArray(
								imagesNews.map((news) => {
									return (
										<>
											<SwiperSlide key={news?.idImage}>
												{
													<figure
														className={`slideShow__figurePreviewDesktop`}
													>
														<div
															className="bg-[#000] aspect-16:9"
														>
															<div
																className="relative flex justify-center swiper-zoom-container"
															>
																<Image
																	src={news[typeOfRatio]}
																	width={500}
																	height={500}
																	quality={75}
																	sizes="(max-width:834px) 100vw"
																	alt={news?.altImage}
																	className="image-custom overflow-hidden relative"
																	style={
																		{
																			"--heightImage": `${showPreview.desktop.heightImage || ""}`,
																			"--widthImage": `${showPreview.desktop.widthImage || ""}`,
																			"--aspect-ratio": `${showPreview.desktop.aspect || ""}`,
																		} as React.CSSProperties
																	}
																/>
															</div>
														</div>
														<figcaption
															className="p-4 text-neutral-inverted bg-[#141515]"
														>
															<h2
																className="text-lg font-bold mb-4 leading-6"
															>
																{news.editorialTitle.slice(
																	0,
																	maxTitleCharacters
																)}
															</h2>
															{/* * Set the div because the "parse" set a <p></p> Tag */}
															<div
																className="slideShow__descriptionPreviewDesktop"
															>
																{parse(news.description)}
															</div>
														</figcaption>
													</figure>
												}
											</SwiperSlide>
										</>
									);
								})
							)}
							<div
								className="flex justify-around items-center mt-4 h-8 w-full bottom-4 z-[1] max-w-[1440px]"
							>
								<div
									className={`swiper-button-prev previewActionButton`}
									style={
										{
											"--swiper-navigation-size": "16px",
										} as React.CSSProperties
									}
								/>
								<div
									className="slideShow__paginationButtonsPreview"
									style={
										{
											"--swiper-pagination-bullet-inactive-color":
												"rgba(255, 255, 255, 0.40)",
											"--swiper-pagination-color": "#fff",
											"--swiper-pagination-bullet-inactive-opacity": "1",
										} as React.CSSProperties
									}
								/>
								<div
									className={`swiper-button-next previewActionButton`}
									style={
										{
											"--swiper-navigation-size": "16px",
										} as React.CSSProperties
									}
								/>
							</div>
						</Swiper>
					</div>
				</>
			)}
		</>
	);
};

export default SlideShow;
