import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";

type DurType = {
	duration: any;
	isPlay?: boolean;
	isSmall?: boolean;
	isTopFive?: boolean;
};
export default function VideoDuration({ duration, isPlay, isSmall, isTopFive }: DurType) {
	return (
		<time className="absolute right-2 bottom-2 bg-secondary-90 text-secondary-inverted pt-0.5 pb-0.5 pr-5 pl-2 rounded-md text-sm after:absolute play-icon after:w-5 after:h-5 after:right-0.5 after:top-0" suppressHydrationWarning>
			{secondsToMinutes(duration)}
		</time>
	);
}
