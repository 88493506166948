
import { HeroBlock } from "@/app/types";
import { BlockPropsStructuredData } from "@/app/types/BlockProps.type";
import { upperCamelCase } from "../../../helpers/upperCamelCase/upperCamelCase";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";

import SendDataLayer from "@/app/hooks/analytics/SendDataLayer";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetHero from "@/app/queries/getHero";
import TapToplayCoverBig from "@/app/components/molecules/TapToPlayCoverBig/TapToPlayCoverBig";
import VideoDuration from "../../atoms/VideoDuration/VideoDuration";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";
import ClientImage from "../../atoms/ClientImage/ClientImage";
import CardYoutube from "../../molecules/cards/CardYoutube/CardYoutube";
import ListItem from "@/app/site-tools/structured-data/ListItem";

export const BloqueHero = async ({
	type,
	id,
	position = 0,
	total,
	enabledStructureData = false
}: BlockPropsStructuredData) => {
	const { data, ...rest } = await fetchApolloData(GetHero, {
		openingBlockId: id,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const blockData: HeroBlock = data?.getHero ?? null;
	const blockTypeLayer = type
		.replace("block_content--", "") 
		.split("_")
		.join(" ");

	const blockDataLayer = {
		clickBloque: 0,
		content_name: blockData?.content?.data?.isVideo
			? "VOD>"
			: blockData?.content?.data?.isLiveblog
			? "liveblog>"
			: "NEWS>",
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${
			blockData?.configurationType === "Editorial" ? "2" : "1"
		} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: "16:9",
		event: "select_content",
		eventAction: "click",
		posicionBloque: position ? position + 1 : 0,
		subBloque: upperCamelCase(blockData?.configurationType),
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer),
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
		contentTitle: undefined || "",
	};

	const blockType = blockData?.configurationType.toLowerCase().replace(" ", "");
	const isDark = blockData?.isDark;
	const hasTitle = blockData?.hasTitle;

	const post = blockData?.content?.data;
	blockDataLayer.contentTitle = post?.title;
	const postDuration = post?.duration ?? 0;
	const postDurationSeparator = "|";
	const postDate = new Date(post?.date);
	const postDateMonth = postDate.toLocaleString("es-MX", { month: "long" });
	const postDateString =
		postDateMonth.charAt(0).toUpperCase() +
		postDateMonth.slice(1) +
		" " +
		postDate.toLocaleString("es-MX", { day: "numeric" }) +
		", " +
		postDate.toLocaleString("es-MX", { year: "numeric" });

	const isBreaking = blockData?.content?.isBreaking;
	const hasSummary = blockData?.content?.hasSummary;
	const isVideo = post?.isVideo;
	const isTapToPlay = blockData?.content.isTapToPlay;
	const hasTerms = post?.term.length > 0;

	let isFirstFold = false;

	if (position === 0 && blockType === "hero2") {
		isFirstFold = true;
	}

	const videoData = {
		blocktitle: blockData?.liveAttr.title,
		blockSummary: blockData?.liveAttr.description,
		youtubeID: blockData?.liveAttr.code,
	};
	const IS_VALID = (enabledStructureData && blockType.toLowerCase() !== "singleshow");
	return (
		<section className="w-full flex justify-center">
			<div className="w-full max-w-[1440px]">
				{hasTitle && (
					<WidgetTitle
						title={blockData?.blockTitle}
						text={blockData?.blockDescription}
						lineColor={blockData?.borderColor}
						isDark={isDark}
					/>
				)}
				{blockType === "singleshow" && (
					<section>
						<CardYoutube data={videoData} />
						<p>{blockData?.liveAttr.description}</p>
					</section>
				)}
				{blockType === "editorial" && (
					<section
						className=""
					>
						{isTapToPlay && isVideo && (
							<figure
								id={post?.id}
							>
								<SendDataLayer blockData={blockDataLayer} cardPosition={1}>
									<TapToplayCoverBig
										cover={post}
										hero={false}
										isTapToPlay={isTapToPlay}
										priority={
											position === 0 || position === 1 || position === 2
										}
									/>
								</SendDataLayer>
								<figcaption>
									{isBreaking && (
										<span className="">
											{blockData?.content.textBreaking}
										</span>
									)}
									{!isBreaking && <span className="">{post?.term?.[0]?.name}</span>}
									<InteractiveLink
										blockData={blockDataLayer}
										url={post?.path || "/"}
										cardPosition={1}
									>
										<h3>{post?.title}</h3>
									</InteractiveLink>
									<InteractiveLink
										blockData={blockDataLayer}
										cardPosition={1}
										url={post?.path || "/"}
									>
										<p
											className=""
										>
											{post?.summary}
										</p>
									</InteractiveLink>
								</figcaption>
							</figure>
						)}
						{!isTapToPlay && (
							<figure id={post?.id}>
								<div className="">
									<InteractiveLink
										blockData={blockDataLayer}
										url={post?.path ?? "/"}
										title={post?.title}
										cardPosition={1}
									>
										<ClientImage
											priority={position === 0}
											thumbnail={post?.thumbnail}
											customsizes="(min-width: 420px) 100vw, (min-width: 768px) 100vw, (min-width:1024px) 100vw, (min-width: 1200px) 100vw"
										/>
										{post?.isLiveblog && post?.status && (
											<div
												className=""
											>
												<div
													className=""
												>
													LIVEBLOG
												</div>
												<div
													className=""
												/>
											</div>
										)}
									</InteractiveLink>
									{isVideo && (
										<VideoDuration duration={post?.duration} isPlay={false} />
									)}
								</div>
								<figcaption>
									{isBreaking && (
										<span className="">
											{blockData?.content.textBreaking}
										</span>
									)}
									{!isBreaking && <span className="">{post?.term?.[0]?.name}</span>}
									<InteractiveLink
										url={post?.path ?? "/"}
										title={post?.title}
										cardPosition={1}
										blockData={blockDataLayer}
									>
										<h3>{post?.title}</h3>
									</InteractiveLink>

									<InteractiveLink
										url={post?.path ?? "/"}
										title={post?.title}
										cardPosition={1}
										blockData={blockDataLayer}
									>
										<p>
											{post?.summary}
										</p>
									</InteractiveLink>
								</figcaption>
							</figure>
						)}
					</section>
				)}
				{blockType === "hero2" && (
					<section
						className="bg-secondary-90 p-8"
					>
						{isTapToPlay && isVideo && (
							<figure id={post?.id}>
								<SendDataLayer blockData={blockDataLayer} cardPosition={1}>
									<TapToplayCoverBig
										cover={post}
										hero={true}
										priority={
											position === 0 || position === 1 || position === 2
										}
										first={true}
									/>
								</SendDataLayer>
								<figcaption>
									<div>
										{isBreaking && (
											<span className="">
												{blockData?.content.textBreaking}
											</span>
										)}
										{!isBreaking && <span>{post?.term?.[0]?.name}</span>}
										<h3>{post?.title}</h3>
										{hasSummary && (
											<p
												className=""
											>
												{post?.summary}
											</p>
										)}
										<p className="">
											{postDateString}{" "}
											{isVideo && <span>{postDurationSeparator}</span>}{" "}
											{isVideo && (
												<span>
													<b>{"Duración: "}</b>
													<time>{secondsToMinutes(postDuration)}</time>
													&nbsp;mins
												</span>
											)}
										</p>
									</div>
								</figcaption>
							</figure>
						)}
						{!isTapToPlay && (
							<figure id={post?.id} className="relative">
								<div className="">
									<InteractiveLink
										url={post?.path ?? "/"}
										title={post?.title}
										blockData={blockDataLayer}
										cardPosition={1}
									>
										<ClientImage
											priority={position === 0}
											thumbnail={post?.thumbnail}
											customsizes="(min-width: 420px) 100vw, (min-width: 768px) 100vw, (min-width:1024px) 100vw, (min-width: 1200px) 100vw"
										/>
										{post?.isLiveblog && post?.status && (
											<div
												className=""
											>
												<div
													className=""
												>
													LIVEBLOG
												</div>
												<div
													className=""
												/>
											</div>
										)}
									</InteractiveLink>
								</div>
								<figcaption className="md:absolute w-full md:left-0 md:bottom-0 bg-gradient-to-b from-[rgb(0,0,0)]/0 to-secondary-90 to-65% p-2 md:p-6 text-secondary-inverted">
									<div>
										{isBreaking && (
											<span className="">
												{blockData?.content.textBreaking}
											</span>
										)}
										{post?.isLiveblog && post?.status && (
											<>
												<div
													className=""
												>
													<div
														className=""
													>
														<div
															className=""
														>
															LIVEBLOG
														</div>
														<div
															className=""
														/>
													</div>
													<span className="">
														{post?.term?.[0]?.name}
													</span>
												</div>
												<span
													className=""
												>
													{post?.term?.[0]?.name}
												</span>
											</>
										)}

										{!isBreaking && hasTerms && !post?.isLiveblog && (
											<span>{post?.term?.[0]?.name}</span>
										)}
										<h3 className="text-2xl lg:text-3xl font-black leading-tight mb-1">
											<InteractiveLink
												url={post?.path ?? "/"}
												title={post?.title}
												blockData={blockDataLayer}
											>
												{post?.title}
											</InteractiveLink>
										</h3>
										{hasSummary && <p>{post?.summary}</p>}
										<p className="text-sm md:text-md">
											<InteractiveLink
												url={post?.path ?? "/"}
												title={post?.title}
												blockData={blockDataLayer}
											>
												{postDateString}{" "}
												{isVideo && <span>{postDurationSeparator}</span>}{" "}
												{isVideo && (
													<span>
														<b>{"Duración: "}</b>
														<time>{secondsToMinutes(postDuration)}</time>
														&nbsp;mins
													</span>
												)}
											</InteractiveLink>
										</p>
									</div>
								</figcaption>
							</figure>
						)}
					</section>
				)}
			</div>
			{ IS_VALID ? ListItem([post], true) : "" }
		</section>
	);
};
export default BloqueHero;
