"use client";

import CardVideoNews from "../CardVideoNews/CardVideoNews";
import { BlockDataType } from "@/app/types/BlockDataType";
import ListItem from "@/app/site-tools/structured-data/ListItem";

type Props = {
	videos: any;
	isDark: boolean;
	blockData: BlockDataType;
	enabledStructureData?: boolean;
};

export default function CarouselVOD({ videos, isDark, blockData, enabledStructureData = false }: Props) {

	return (
		<div className="flex justify-start flex-nowrap overflow-x-scroll overflow-y-hidden no-scrollbar pr-4 lg:pr-0 xl:gap-4 pt-4">
			{videos &&
				videos.map((video: any, i: number) => {
					return (
						<CardVideoNews
							key={`fig-${video.id}-${i}`}
							post={video}
							cardPosition={i + 1}
							isDark={isDark}
							blockData={blockData}
						/>
					);
				})
			}
			{ enabledStructureData ? ListItem(videos, true) : "" }
		</div>
	);
}
