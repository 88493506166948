import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetSlideShow from "@/app/queries/getSlideShow";
import { GetSlideShowInterface } from "./interfaces/SlideShow.interface";
import SlideShow from "../../molecules/SlideShow/SlideShow";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";

type SlideShowProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};
export const BlockSlideShow = async ({
	type,
	id,
	position = 0,
	total,
}: SlideShowProps) => {
	const { data, ...rest } = await fetchApolloData(GetSlideShow, {
		slideShowId: id,
	});

	if (rest?.error) console.error(rest?.error);
	const {
		cmsName,
		lineColor,
		subtitle,
		...slideShowRest
	}: GetSlideShowInterface = data?.getSlideShow;

	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";

	const blockDataLayer = {
		contenidosBloque: `${slideShowRest?.imagesNews?.length} contenidos`,
		countWidget: `${total}` ?? "0",
		estiloBloque: slideShowRest.aspectRatio,
		event: "select_content",
		eventAction: "click",
		posicionBloque: position + 1 ? `${position + 1}` : "0",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
		tipoElemento: "widget",
		tituloBloque: slideShowRest?.blockTitle ?? undefined,
		descripcionBloque: cmsName,
		colorBloque: slideShowRest?.theme,
		clickBloque: undefined,
		content_type: undefined,
		section: undefined,
		contentTitle: undefined,
	};
	const loading =
		parseInt(blockDataLayer?.posicionBloque) === 0 ||
		parseInt(blockDataLayer?.posicionBloque) === 1 ||
		parseInt(blockDataLayer?.posicionBloque) === 2
			? "eager"
			: "lazy";

	const STYLES: { [key: string]: string } = {
		"#FF3640": "border-primary-60",
		"#1DB0D5": "border-link"
	};
	const BACKGROUND = slideShowRest.theme === "light" ? "" : "bg-secondary-90";
	const FOREGROUND = slideShowRest.theme === "light" ? "text-secondary-default" : "text-secondary-inverted";
	const BORDER = lineColor && slideShowRest?.blockTitle ? `border-t-4 border-solid ${STYLES[lineColor] || ""}` : "";

	return (
		<div className={BACKGROUND}>
			<section className={`${BACKGROUND} max-w-[1440px] my-0 mx-auto`}>
				{slideShowRest.blockTitle && (
					<div className="px-4 py-2 lg:p-4 lg:m-0 xl:pt-8 text-secondary-inverted">
						<h2
							className={`${FOREGROUND} ${BORDER} pt-2 text-2xl font-black leading-7 lg:text-3xl lg:leading-8 lg:letter lg:-tracking-[.56px]`}
						>
							{slideShowRest?.blockTitle || ""}
						</h2>
						{subtitle && (
							<h3
								className={`${FOREGROUND} text-md font-normal leading-[14px] xl:text-base xl:leading-[22px]}`}
							>
								{subtitle}
							</h3>
						)}
					</div>
				)}
				<SlideShow
					{...slideShowRest}
					dataLayer={blockDataLayer}
					loading={loading}
				/>
			</section>
		</div>
	);
};
